import React from "react";
import ReactDOM from "react-dom";

import Hero from "./components/Hero";
const heroContainer = document.getElementById("hero-slider");

if (heroContainer) {
  const { slides } = heroContainer.dataset;
  ReactDOM.render(<Hero slides={slides} />, heroContainer);
}
