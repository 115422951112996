import React, { Component } from "react";
import LineItem from "./LineItem";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.openCheckout = this.openCheckout.bind(this);
    this.updateTaxReceipt = this.updateTaxReceipt.bind(this);
    this.updateGiftCardEveryItem = this.updateGiftCardEveryItem.bind(this);
    this.updateGroupName = this.updateGroupName.bind(this);
  }

  openCheckout() {
    window.open(this.props.checkout.webUrl);
  }

  updateTaxReceipt(event) {
    const value = !event.target.checked;
    this.props.updateTaxReceipt(value);
  }

  updateGiftCardEveryItem(event) {
    const value = event.target.checked;
    this.props.updateGiftCardEveryItem(value);
  }

  updateGroupName(event) {
    const value = event.target.value;
    this.props.updateGroupName(value);
  }

  render() {
    let line_items = this.props.checkout.lineItems.map((line_item) => {
      return (
        <LineItem
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          key={line_item.id.toString()}
          line_item={line_item}
        />
      );
    });

    return (
      <div className={`Cart ${this.props.isCartOpen ? "Cart--open" : ""}`}>
        <header className="Cart__header">
          <h2>Your Cart</h2>
          <button onClick={this.props.handleCartClose} className="Cart__close">
            <i className="fas fa-times-circle"></i>
            Close to keep shopping
          </button>
        </header>
        {this.props.checkout.lineItems.length > 0 ? (
          <ul className="Cart__line-items list-unstyled">{line_items}</ul>
        ) : (
          <div className="Cart__line-items">
            <p className="lead text-center">There are no items in your cart.</p>
          </div>
        )}
        <footer className="Cart__footer">
          <div className="Cart-info clearfix">
            <div className="Cart-info__total Cart-info__small">Total</div>
            <div className="Cart-info__pricing">
              <span className="pricing">
                $ {this.props.checkout.totalPrice}
              </span>
            </div>
          </div>
          {/* <div className="form-group">
            <label htmlFor="sticker">
              How many window stickers would you like to receive?
            </label>
            <input
              id="sticker"
              type="number"
              value={this.props.sticker}
              className="form-control"
              onChange={this.props.handleStickerChange}
            />
          </div> */}
          {/* <div className="form-group">
            <label htmlFor="giftCard">
              How many generic gift cards would you like to receive?
            </label>
            <input
              id="giftCard"
              type="number"
              value={this.props.giftCard}
              className="form-control"
              onChange={this.props.handleGiftCardChange}
            />
          </div> */}
          <p>
              Please indicate if you would like a gift card for every item ordered.
          </p>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="giftCardEveryItem"
              checked={this.props.giftCardEveryItem}
              onChange={this.updateGiftCardEveryItem}
            />
            <label className="form-check-label">
              Yes
            </label>
          </div>
          <p>
            CRA allows us to only send tax receipts to individual donors.{" "}
            <strong>
              Please indicate if you are donating funds raised on behalf of a
              church or other group.
            </strong>
          </p>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="is-group"
              checked={!this.props.taxReceipt}
              onChange={this.updateTaxReceipt}
            />
            <label className="form-check-label" htmlFor="is-group">
              Yes
            </label>
          </div>
          {!this.props.taxReceipt && (
            <div className="form-group">
              <label htmlFor="group-name">Name of Group</label>
              <input
                type="text"
                className="form-control"
                id="group-name"
                onChange={this.updateGroupName}
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="hear-about-us">
              How did you hear about the World of Gifts?
            </label>
            <select
              id="hear-about-us"
              name="hear-about-us"
              className="form-control"
              onChange={this.props.handleHearAboutUsChange}
            >
              <option value="">Select an Option</option>
              <option value="ParentsCanada Magazine">
                ParentsCanada Magazine
              </option>
              <option value="PWRDF">PWRDF</option>
              <option value="My Church">My Church</option>
              <option value="The Anglican Journal">The Anglican Journal</option>
              <option value="My Diocesan Newspaper">
                My Diocesan Newspaper
              </option>
              <option value="Other">Other</option>
            </select>
          </div>
          <button
            className="Card_gift btn btn-light btn-block"
            onClick={this.props.handleGiftOpen}
          >
            Send an eCard
          </button>
          <button
            className="Cart__checkout btn btn-primary btn-block"
            onClick={this.openCheckout}
          >
            Checkout
          </button>
        </footer>
      </div>
    );
  }
}

export default Cart;
