import React from 'react';

import Map from './Map';
import SearchBox from './SearchBox';

import dioceses from './data/dioceses';

class DioceseMap extends React.Component {
  constructor() {
    super();
    this.state = {
      message: false,
      diocese: false,
      dioceses: [],
      polys: [],
      currentPoly: false
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.getMessage = this.getMessage.bind(this);
    this.resetDiocese = this.resetDiocese.bind(this);
  }

  getMessage() {
    const { diocese, message, isSearcing } = this.state;

    const classes = "lead mb-0 bg-primary text-white p-3";

    if(isSearcing) {
      return <p className={classes}>Searching...</p>;
    } else if (diocese) {
      return <p className={classes}>Your Diocese is {diocese}</p>;
    } else if (message) {
      return <p className={classes}>{message}</p>;
    }

    return <p className={classes}>Enter your address in the preceeding field to find your Diocese.</p>;
  }

  handleSearch(places) {
    if (places.length === 0) {
      this.resetDiocese('Enter your address in the preceeding field to find your Diocese.');
      return;
    }

    this.setState({ isSearcing: true });

    places.forEach(place => {
      if(!place.geometry) {
        this.resetDiocese('We could not find the place you search for. Please, try again.');
        return;
      }

      this.state.polys.forEach(( poly, index ) => {
        if (google.maps.geometry.poly.containsLocation(place.geometry.location, poly) ) {
          const diocese = this.state.dioceses[index];
          this.setState({
            diocese: diocese,
            currentPoly: index,
            message: false
           });
          return;
        }
      });

      if(!this.state.currentPoly) {
        this.resetDiocese('Sorry, we could not find out which Diocese you belong to.');
      }
    });

    this.setState({ isSearcing: false });
  }

  resetDiocese(message){
    this.setState({
      diocese: false,
      currentPoly: false,
      message: message,
    });
  }

  render() {
    const { polys, currentPoly } = this.state;
    let polygonPaths = false;
    if (currentPoly) {
      polygonPaths = polys[currentPoly].getPaths();
    }
    return (
      <div className="my-5">
        <h2>Don’t know your diocese?</h2>
        <p className="lead">Enter your postal code to find your Diocesan Representative or Youth Council Representative</p>
        <SearchBox placeholder="Enter Postal Code or Address" onPlacesChanged={this.handleSearch} />
        {this.getMessage()}
      </div>
    );
  }

  componentDidMount(){
    let polys = [];
    let d = [];
    dioceses.forEach(diocese => {
      polys.push(new google.maps.Polygon({paths: diocese.coords,}));
      d.push(diocese.name);
    });
    this.setState({ polys: polys, dioceses: d });
  }
}

export default DioceseMap;
