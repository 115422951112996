import React, {Component, Fragment} from 'react';
const { templateUrl } = window.wpvars;

class Gift extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      name: '',
      image: false,
      images: Array.apply(null, Array(29)).map(function (_, i) { return i+1; }) ,
    };

    this.updateGift = this.updateGift.bind(this);
    this.setImage = this.setImage.bind(this);
    this.openCheckout = this.openCheckout.bind(this);
  }

  openCheckout() {
    this.props.handleGiftClose();
    window.open(this.props.checkout.webUrl);
  }

  updateGift(event){

    const target = event.target;

    this.setState({[target.name]: target.value}, () => {
      this.props.updateGift({
        name: this.state.name,
        email: this.state.email,
        image: this.state.image
      });
    }, 500);

  }

  setImage(image) {
    this.setState( { image: image }, () => {
      this.props.updateGift({
        name: this.state.name,
        email: this.state.email,
        image: this.state.image.toString()
      });
    }, 500 );
  }

  render() {
    return(
      <Fragment>
        <div className={ `Storefront__Gift modal${ this.props.isGiftOpen ? ' show' : ''}`} tabIndex="-1" role="dialog" style={ { display: this.props.isGiftOpen  ? 'block' : 'none' } } >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">e-Card Recipient</h5>
                <button type="button" className="close" aria-label="Close" onClick={this.props.handleGiftClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="recipient-name">Recipient Name</label>
                      <input type="text" id="recipient-name" name="name" className="form-control" onChange={this.updateGift} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="recipient-email">Recipient Email</label>
                      <input type="email" id="recipient-email" name="email" className="form-control" onChange={this.updateGift} />
                    </div>
                  </div>
                </div>
                <p>Click on the image that matches your gift.</p>
                <div className="images">
                  { this.state.images.map( ( image ) => {
                    return (
                      <button key={image} onClick={() => this.setImage( image )} className={ this.state.image === image ? 'selected' : null } style={ { backgroundImage: `url( ${ templateUrl }/img/ecards/thumbs/thumb-${ image }.jpg )` } }></button>
                    );
                  } ) }
                </div>
                { this.state.image &&
                  <img src={ `${ wpvars.templateUrl }/img/ecards/e-${ this.state.image }.jpg` } alt="" className="img-fluid img-preview" />
                }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={this.props.handleGiftClose}>Continue Shopping</button>
                {/* <button type="button" className="btn btn-primary" onClick={this.openCheckout}>Checkout</button> */}
              </div>
            </div>
          </div>
        </div>
        { this.props.isGiftOpen &&
          <div className="modal-backdrop fade show"></div>
        }
      </Fragment>
    );
  }
}

export default Gift;
