import React from 'react';
import ReactDOM from 'react-dom';

import DioceseMap from './components/DioceseMap';

if( document.getElementById( 'diocese-map' ) ) {
  ReactDOM.render(
    <DioceseMap />,
    document.getElementById( 'diocese-map' )
  );
}
