import React, {Component} from 'react';

class LineItem extends Component {
  constructor(props) {
    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);
    this.incrementQuantity = this.incrementQuantity.bind(this);
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  render() {
    return (
      <li className="Line-item media">
        {this.props.line_item.variant.image ? <img className="mr-3" style={{width:64 + 'px'}} src={this.props.line_item.variant.image.src} alt={`${this.props.line_item.title} product shot`}/> : null}
        <div className="Line-item__content media-body">
            <div className="Line-item__variant-title h5 mt-0 mb-1">
				{this.props.line_item.title}
            </div>
            <div className="Line-item__quantity-container text-center my-1 clearfix">
              <button className="Line-item__quantity-update btn btn-primary btn-sm pull-left" onClick={() => this.decrementQuantity(this.props.line_item.id)}>
			  	<span className="sr-only">Decrement Quantity</span>
			  	-
			  </button>
              <span className="Line-item__quantity px-2">{this.props.line_item.quantity}</span>
              <button className="Line-item__quantity-update btn btn-primary btn-sm pull-right" onClick={() => this.incrementQuantity(this.props.line_item.id)}>
			    <span className="sr-only">Increment Quantity</span>
			    +
			  </button>
            </div>
			<div className="Line-item__footer clearfix my-1">
				<span className="Line-item__price text-dark">
				$ { (this.props.line_item.quantity * this.props.line_item.variant.price).toFixed(2) }
				</span>
				<button className="Line-item__remove btn btn-danger btn-sm" onClick={()=> this.props.removeLineItemInCart(this.props.line_item.id)}>×</button>
			</div>
          </div>
      </li>
    );
  }
}

export default LineItem;
