import React from "react";
import SwiperCore, {
  Autoplay,
  A11y,
  EffectFade,
  Keyboard,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Autoplay, A11y, EffectFade, Keyboard, Pagination]);

class Hero extends React.Component {
  render() {
    const slides = JSON.parse(this.props.slides);
    return (
      <div className="my-5">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          effect="fade"
          loop={true}
          pagination={{ clickable: true }}
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.image}>
              <div className="py-5">
                <div class="masked-image px-5">
                  <img
                    class="mask img-fluid rounded-circle w-100 px-5"
                    src={`${wpvars.templateUrl}/img/mask.png`}
                    alt=""
                  />
                  <img
                    src={slide.image}
                    alt=""
                    class="landing-img shadow img-fluid w-100 rounded-circle"
                  />
                </div>
                <div class="caption py-4">
                  <p class="h2 text-primary my-0">{slide.line_two}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
}

export default Hero;
