import React, { Component } from 'react';
import VariantSelector from './VariantSelector';
import sanitizeHtml from 'sanitize-html';

class Product extends Component {
  constructor(props) {
    super(props);

    let defaultOptionValues = {};
    this.props.product.options.forEach(selector => {
      defaultOptionValues[selector.name] = selector.values[0].value;
    });
    this.state = { selectedOptions: defaultOptionValues };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.findImage = this.findImage.bind(this);
  }

  findImage(images, variantId) {
    const primary = images[0];

    const image = images.filter(function(image) {
      return image.variant_ids.includes(variantId);
    })[0];

    return (image || primary).src;
  }

  handleOptionChange(event) {
    const target = event.target;
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.client.product.helpers.variantForOptions(
      this.props.product,
      selectedOptions
    );

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image
    });
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value
    });
  }

  render() {
    let variantImage =
      this.state.selectedVariantImage || this.props.product.images[0];
    let variant = this.state.selectedVariant || this.props.product.variants[0];
    let variantQuantity = this.state.selectedVariantQuantity || 1;
    const cleanDescription = sanitizeHtml(this.props.product.descriptionHtml, {
      allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p'],
      allowedAttributes: {
        a: ['href', 'target']
      }
    });

    return (
      <div className="col-md-4 d-flex mb-3">
        <div className="Product card">
          {this.props.product.images.length ? (
            <img
              src={variantImage.src}
              className="card-img-top"
              alt={`${this.props.product.title} product shot`}
            />
          ) : null}
          <div className="card-body">
            <h2 className="card-title h3 Product__title">
              {this.props.product.title}
              <br />
              <small className="Product__price">${variant.price}</small>
            </h2>
            <div
              className="card-content"
              dangerouslySetInnerHTML={{ __html: cleanDescription }}
            ></div>
          </div>
          <div className="card-footer">
            {this.props.product.variants.length > 1 && (
              <div className="input-group mb-2">
                <label
                  className="sr-only"
                  htmlFor={this.props.product.id + 'variant'}
                >
                  Amount
                </label>
                <select
                  className="form-control"
                  name="Amount"
                  onChange={e => this.handleOptionChange(e)}
                >
                  {this.props.product.variants.map(v => (
                    <option key={v.id} value={v.title}>
                      {v.title} - ${v.price}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <label
                  className="input-group-text"
                  htmlFor={this.props.product.id + '-quantity'}
                >
                  Quantity
                </label>
              </div>
              <input
                min="1"
                className="form-control"
                type="number"
                id={this.props.product.id + '-quantity'}
                defaultValue={variantQuantity}
                onChange={this.handleQuantityChange}
              ></input>
            </div>
            <button
              className="Product__buy btn btn-primary btn-block"
              onClick={() =>
                this.props.addVariantToCart(variant.id, variantQuantity)
              }
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
