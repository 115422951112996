import React, { Component } from 'react';

class Collections extends Component {
	render() {
		let collections = this.props.collections.map((collection) => {
			var classes = `btn btn-link nav-item nav-link${ collection.id === this.props.selectedCollection ? ' active' : '' }`;
			return (
				<button
					onClick={() => this.props.setSelectedCollection(collection.id)}
					key={collection.id.toString()}
					className={classes}
				>
					{collection.title}
				</button>
			);
		});

		let classes = `btn btn-link nav-link nav-item${this.props.selectedCollection === '' ? ' active' : ''}`;

		return (
			<div className="Collection-wrapper my-3">
				<nav className="nav nav-pills nav-justified mb-3">
					<button
						className={classes}
						onClick={this.props.showAllProducts}
					>
						Show All
					</button>
					{collections}
				</nav>
			</div>
		);
	}
}

export default Collections;
