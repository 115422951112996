import { algoma } from './coords/algoma';
import { arctic } from './coords/arctic';
import { athabasca } from './coords/athabasca';
import { brandon } from './coords/brandon';
import { britishColumbia } from './coords/britishColumbia';
import { caledonia } from './coords/caledonia';
import { calgary } from './coords/calgary';
import { centralNewfoundland } from './coords/centralNewfoundland';
import { eastNewfoundland } from './coords/eastNewfoundland';
import { edmonton } from './coords/edmonton';
import { fredericton } from './coords/fredericton';
import { huron } from './coords/huron';
import { kootenay } from './coords/kootenay';
import { mishamokoweesh } from './coords/mishamokoweesh';
import { montreal } from './coords/montreal';
import { moosonee } from './coords/moosonee';
import { newWestminster } from './coords/newWestminster';
import { niagara } from './coords/niagara';
import { novaScotia } from './coords/novaScotia';
import { ontario } from './coords/ontario';
import { ottawa } from './coords/ottawa';
import { quAppelle } from './coords/quAppelle';
import { quebec } from './coords/quebec';
import { rupertsLand } from './coords/rupertsLand';
import { saskatchewan } from './coords/saskatchewan';
import { saskatoon } from './coords/saskatoon';
import { territoryOfThePeople } from './coords/territoryOfThePeople';
import { toronto } from './coords/toronto';
import { westNewfoundland } from './coords/westNewfoundland';
import { yukon } from './coords/yukon';

const dioceses = [
  {
    name: "Algoma",
    coords: algoma,
  },
  {
    name: "Arctic",
    coords: arctic,
  },
  {
    name: "Athabasca",
    coords: athabasca,
  },
  {
    name: "Brandon",
    coords: brandon,
  },
  {
    name: "British Columbia",
    coords: britishColumbia,
  },
  {
    name: "Caledonia",
    coords: caledonia,
  },
  {
    name: "Calgary",
    coords: calgary,
  },
  {
    name: "Central Newfoundland",
    coords: centralNewfoundland,
  },
  {
    name: "Eastern Newfoundland and Labrador",
    coords: eastNewfoundland,
  },
  {
    name: "Edmonton",
    coords: edmonton,
  },
  {
    name: "Fredericton",
    coords: fredericton,
  },
  {
    name: "Huron",
    coords: huron,
  },
  {
    name: "Kootenay",
    coords: kootenay,
  },
  {
    name: "Mishamokoweesh",
    coords: mishamokoweesh,
  },
  ,
  {
    name: "Montreal",
    coords: montreal,
  },
  {
    name: "Moosonee",
    coords: moosonee,
  },
  {
    name: "New Westminster",
    coords: newWestminster,
  },
  {
    name: "Niagara",
    coords: niagara,
  },
  {
    name: "Nova Scotia",
    coords: novaScotia,
  },
  {
    name: "Ontario",
    coords: ontario,
  },
  {
    name: "Ottawa",
    coords: ottawa,
  },
  {
    name: "Qu'Appelle",
    coords: quAppelle,
  },
  {
    name: "Quebec",
    coords: quebec,
  },
  {
    name: "Rupert's Land",
    coords: rupertsLand,
  },
  {
    name: "Saskatchewan",
    coords: saskatchewan,
  },
  {
    name: "Saskatoon",
    coords: saskatoon,
  },
  {
    name: "Territory of the People",
    coords: territoryOfThePeople,
  },
  {
    name: "Toronto",
    coords: toronto,
  },
  {
    name: "Western Newfoundland",
    coords: westNewfoundland,
  },
  {
    name: "Yukon",
    coords: yukon,
  }
];

export default dioceses;
