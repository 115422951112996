import _ from "lodash";
import React from 'react';
import { compose, withProps } from 'recompose';
import { withGoogleMap, GoogleMap, Polygon } from 'react-google-maps';

const BaseMap = compose(
  withProps({
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(props =>
  <GoogleMap defaultCenter={{lat: 55.6425089,lng: -94.1874793}} defaultZoom={3.5}>
    { props.polygonPaths &&
      <Polygon
        paths={props.polygonPaths}
        options={{
          strokeColor: '#652C90',
          strokeWeight: 1,
          fillColor: '#652C90',
          fillOpacity: 0.4
        }}
      />
    }
  </GoogleMap>
);

class Map extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BaseMap polygonPaths={this.props.polygonPaths} />
    );
  }

}

export default Map;
