import React from 'react';
import ReactDOM from 'react-dom';

class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.onPlacesChanged = this.onPlacesChanged.bind(this);
  }
  render() {
    const divStyle = {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: 'none'
    };
    return <input ref="input" style={divStyle} className="form-control form-control-lg" placeholder={this.props.placeholder} type="text " />
  }
  onPlacesChanged() {
    if(this.props.onPlacesChanged) {
      this.props.onPlacesChanged(this.searchBox.getPlaces());
    }
  }
  componentDidMount() {
    const input = ReactDOM.findDOMNode(this.refs.input);
    this.searchBox = new google.maps.places.SearchBox(input);
    this.searchBox.addListener('places_changed', this.onPlacesChanged);
  }
  componentWillUnnount() {
    this.searchBox.removeListener('places_changed', this.onPlacesChanged);
  }
}

export default SearchBox;
