import React, { Component } from "react";

import { withCookies } from "react-cookie";

import Collections from "./Collections";
import Products from "./Products";
import Cart from "./Cart";
import Gift from "./Gift";

class App extends Component {
  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      checkoutId: false,
      isCartOpen: false,
      checkout: {
        id: false,
        lineItems: [],
      },
      selectedCollection: "",
      collections: [],
      products: [],
      shop: {},
      customAttributes: {
        giftCardEveryItem: "Yes",
        taxReceipt: true,
        sticker: 0,
        giftCard: 0,
        groupName: "",
        recipientName: false,
        recipientEmail: false,
        giftImage: false,
        giftImageURL: false,
        hearAboutUs: "",
      },
      isGiftOpen: false,
      groupName: "",
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.setSelectedCollection = this.setSelectedCollection.bind(this);
    this.handleCartClose = this.handleCartClose.bind(this);
    this.addVariantToCart = this.addVariantToCart.bind(this);
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
    this.showAllProducts = this.showAllProducts.bind(this);
    this.updateTaxReceipt = this.updateTaxReceipt.bind(this);
    this.updateGiftCardEveryItem = this.updateGiftCardEveryItem.bind(this);
    this.handleGiftClose = this.handleGiftClose.bind(this);
    this.handleGiftOpen = this.handleGiftOpen.bind(this);
    this.updateGift = this.updateGift.bind(this);
    this.updateCheckout = this.updateCheckout.bind(this);
    this.updateGroupName = this.updateGroupName.bind(this);
    this.getCustomAttributes = this.getCustomAttributes.bind(this);
    this.updateCustomAttributes = this.updateCustomAttributes.bind(this);
    this.handleStickerChange = this.handleStickerChange.bind(this);
    this.handleGiftCardChange = this.handleGiftCardChange.bind(this);
    this.handleHearAboutUsChange = this.handleHearAboutUsChange.bind(this);
  }

  componentWillMount() {
    const checkoutId = this.props.cookies.get("wogCheckoutId") || false;

    if (
      checkoutId &&
      typeof checkoutId !== "undefined" &&
      checkoutId !== "undefined" &&
      checkoutId !== "false"
    ) {
      this.props.client.checkout.fetch(checkoutId).then((res) => {
        this.updateCheckout(res);
      });
    } else {
      this.props.client.checkout
        .create({
          customAttributes: this.getCustomAttributes(),
        })
        .then((res) => {
          this.updateCheckout(res);
        });
    }

    this.props.client.collection.fetchAllWithProducts().then((res) => {
      this.setState({
        collections: res,
      });
    });

    this.props.client.product
      .fetchQuery({
        sortKey: "TITLE",
        first: 50,
      })
      .then((res) => {
        this.setState({
          products: res,
        });
      });

    this.props.client.shop.fetchInfo().then((res) => {
      this.setState({
        shop: res,
      });
    });
  }

  componentDidMount() {
    //window.addEventListener( 'focus', this.handleRefresh )
  }

  componentWillUnmount() {
    //window.removeEventListener( 'focus', this.handleRefresh )
  }

  handleRefresh() {
    if (this.state.isCartOpen || this.state.isGiftOpen) {
      window.location.reload();
    }
  }

  setSelectedCollection(collectionId) {
    this.setState(
      {
        selectedCollection: collectionId,
      },
      () => {
        this.props.client.collection
          .fetchWithProducts(this.state.selectedCollection)
          .then((res) => {
            this.setState({
              products: res.products,
            });
          });
      }
    );
  }

  showAllProducts() {
    this.setState(
      {
        selectedCollection: "",
      },
      () => {
        this.props.client.product.fetchAll().then((res) => {
          this.setState({
            products: res,
          });
        });
      }
    );
  }

  addVariantToCart(variantId, quantity) {
    this.setState({
      isCartOpen: true,
    });

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;

    return this.props.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this.updateCheckout(res);
      });
  }

  updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id;
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];

    return this.props.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        this.updateCheckout(res);
      });
  }

  removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id;

    return this.props.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  }

  handleCartClose() {
    document.body.classList.remove("modal-open");
    this.setState({
      isCartOpen: false,
    });
  }

  handleGiftOpen() {
    document.body.classList.add("modal-open");
    this.setState({
      isGiftOpen: true,
    });
  }

  handleGiftClose() {
    this.setState({
      isGiftOpen: false,
    });
  }

  getCustomAttributes() {
    let customAttributes = [];

    Object.keys(this.state.customAttributes).forEach((key) => {
      if (this.state.customAttributes[key]) {
        customAttributes.push({
          key: key,
          value: this.state.customAttributes[key].toString(),
        });
      }
    });

    return customAttributes;
  }

  updateCustomAttributes(customAttributes) {
    const checkoutId = this.state.checkout.id;

    this.setState({ customAttributes }, () => {
      return this.props.client.checkout
        .updateAttributes(checkoutId, {
          customAttributes: this.getCustomAttributes(),
        })
        .then((res) => {
          this.setState({ checkout: res });
        });
    });
  }

  updateTaxReceipt(sendReceipt) {
    let customAttributes = Object.assign({}, this.state.customAttributes);
    customAttributes.taxReceipt = sendReceipt;

    this.updateCustomAttributes(customAttributes);
  }

  updateGiftCardEveryItem(giftCardEveryItem) {
    let customAttributes = Object.assign({}, this.state.customAttributes);
    customAttributes.giftCardEveryItem = giftCardEveryItem;

    this.updateCustomAttributes(customAttributes);
  }

  updateGroupName(groupName) {
    let customAttributes = Object.assign({}, this.state.customAttributes);
    customAttributes.groupName = groupName;

    this.updateCustomAttributes(customAttributes);
  }

  updateGift(giftMeta) {
    let customAttributes = Object.assign({}, this.state.customAttributes);
    customAttributes.recipientName = giftMeta.name;
    customAttributes.recipientEmail = giftMeta.email;
    customAttributes.giftImage = giftMeta.image;
    if (parseInt(giftMeta.image)) {
      customAttributes.giftImageURL = `${wpvars.templateUrl}/img/ecards/e-${giftMeta.image}.jpg`;
    } else {
      if (this.state.checkout.lineItems.length > 0) {
        customAttributes.giftImageURL = this.state.checkout.lineItems[0].variant.image.src;
      }
    }

    this.updateCustomAttributes(customAttributes);
  }

  handleStickerChange(changeEvent) {
    let customAttributes = Object.assign({}, this.state.customAttributes);
    customAttributes.sticker = changeEvent.target.value;

    this.updateCustomAttributes(customAttributes);
  }

  handleGiftCardChange(changeEvent) {
    let customAttributes = Object.assign({}, this.state.customAttributes);
    customAttributes.giftCard = changeEvent.target.value;

    this.updateCustomAttributes(customAttributes);
  }

  handleHearAboutUsChange(changeEvent) {
    let customAttributes = Object.assign({}, this.state.customAttributes);
    customAttributes.hearAboutUs = changeEvent.target.value;

    this.updateCustomAttributes(customAttributes);
  }

  updateCheckout(newCheckout) {
    this.setState({ checkout: newCheckout }, () => {
      if (this.state.checkout.completedAt) {
        this.props.client.checkout
          .create({
            customAttributes: this.getCustomAttributes(),
          })
          .then((res) => {
            this.setState({ checkout: res }, () => {
              this.props.cookies.set("wogCheckoutId", this.state.checkout.id, {
                path: "/",
              });
            });
          });
      } else {
        this.props.cookies.set("wogCheckoutId", this.state.checkout.id, {
          path: "/",
        });
      }
    });
  }

  render() {
    return (
      <div className="Storefront">
        <div className="clearfix text-right">
          <button
            className="Storefront__view-cart btn btn-primary"
            onClick={() =>
              this.setState({ isCartOpen: !this.state.isCartOpen })
            }
          >
            <i className="fas fa-shopping-cart" /> View Cart
          </button>
        </div>
        <Collections
          collections={this.state.collections}
          client={this.props.client}
          showAllProducts={this.showAllProducts}
          selectedCollection={this.state.selectedCollection}
          setSelectedCollection={this.setSelectedCollection}
        />
        <Products
          products={this.state.products}
          client={this.props.client}
          addVariantToCart={this.addVariantToCart}
        />
        <Cart
          checkout={this.state.checkout}
          taxReceipt={this.state.customAttributes.taxReceipt}
          sticker={this.state.customAttributes.sticker}
          giftCard={this.state.customAttributes.giftCard}
          isCartOpen={this.state.isCartOpen}
          handleCartClose={this.handleCartClose}
          updateQuantityInCart={this.updateQuantityInCart}
          removeLineItemInCart={this.removeLineItemInCart}
          updateTaxReceipt={this.updateTaxReceipt}
          updateGiftCardEveryItem={this.updateGiftCardEveryItem}
          updateGroupName={this.updateGroupName}
          handleGiftOpen={this.handleGiftOpen}
          handleStickerChange={this.handleStickerChange}
          handleGiftCardChange={this.handleGiftCardChange}
          handleHearAboutUsChange={this.handleHearAboutUsChange}
        />
        <Gift
          checkout={this.state.checkout}
          isGiftOpen={this.state.isGiftOpen}
          handleGiftClose={this.handleGiftClose}
          updateGift={this.updateGift}
        />
      </div>
    );
  }
}

export default withCookies(App);
