import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/StoreFront/App';
import Client from 'shopify-buy';
import { CookiesProvider } from 'react-cookie';


const client = Client.buildClient({
	storefrontAccessToken: 'eba959e7553cb9d56b441b118cf02941',
	domain: 'pwrdf-world-of-gifts.myshopify.com'
});

if( document.getElementById( 'pwrdf-storefront' ) ) {
	ReactDOM.render(
    <CookiesProvider>
		  <App client={client}/>
    </CookiesProvider>,
		document.getElementById('pwrdf-storefront')
	);
}
